<template>
  <div class="bg-status" :class="orderStatusBackground">
    <div class="mr-1 dot" :class="orderStatusDot" />
    <ion-text class="status-text" :class="orderStatusColor">{{ orderStatus }}</ion-text>
  </div>
</template>

<script>
import { ORDER_STATUS_NAME } from '@/modules/shared/constants';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    itemInfo: {
      type: Object,
      default: () => {}
    },
    isBookingOrder: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n();
    // cek order status for set background status style
    const orderStatusBackground = computed(() => {
      return props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.COMPLETED
        ? 'bg-green'
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.CANCELLED ||
          props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.BLOCKED
        ? 'bg-red'
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.PROCESSING
        ? 'bg-grey-status'
        : 'bg-orange';
    });

    // cek order status for set dot background status style
    const orderStatusDot = computed(() => {
      return props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.COMPLETED
        ? 'green'
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.CANCELLED ||
          props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.BLOCKED
        ? 'red'
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.PROCESSING
        ? 'grey'
        : 'orange';
    });

    // cek order status for set status title color
    const orderStatusColor = computed(() => {
      return props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.COMPLETED
        ? 'text-green'
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.CANCELLED ||
          props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.BLOCKED
        ? 'text-red'
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.PROCESSING
        ? 'text-grey'
        : 'text-orange';
    });

    // set order status title
    const orderStatus = computed(() => {
      return props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.CANCELLED
        ? t('order.cancelled')
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.PROCESSED
        ? t('order.processed')
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.PROCESSING
        ? t('processing')
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.COMPLETED
        ? t('order.completed')
        : props.itemInfo?.orderStatus.name === ORDER_STATUS_NAME.BLOCKED
        ? t('on_hold')
        : '-';
    });

    return {
      orderStatusBackground,
      orderStatusDot,
      orderStatusColor,
      orderStatus
    };
  }
});
</script>

<style src="../../order-detail/style.scss" lang="scss" scoped></style>
