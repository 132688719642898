<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-header translucent>
        <ion-toolbar>
          <ion-row class="ion-padding text-center">
            <ion-col class="fs-4 fw-bold"> {{ $t('update_price') }} </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>

      <ion-list class="pt-3 pb-3 pl-3 mr-3">
        <div class="text-center mt-3 mb-3 fs-3 fw-500">{{ product.sku.name }}</div>
        <ion-item>
          <ion-label position="stacked">{{ labelPrice }}</ion-label>
          <ion-input
            v-model="unit_price"
            @IonInput="updatePrice($event.target.value)"
            @ionBlur="formatPrice"
          ></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">{{ $t('selling_price') }}</ion-label>
          <ion-input
            v-model="price"
            @IonInput="updateSellingPrice($event.target.value)"
            @ionBlur="formatPrice"
          ></ion-input>
        </ion-item>
      </ion-list>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button
              style="color: #9e9e9e"
              class="ion-margin-top"
              color="#000"
              expand="block"
              fill="outline"
              @click="$emit('close-modal')"
            >
              {{ $t('cancel') }}
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button class="ion-margin-top" expand="block" @click="saveChangePrice()">
              {{ $t('apply') }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonToolbar
} from '@ionic/vue';
// import { ACTIONS } from '@/modules/b2b/store/orders/purchase/actions';
// import { createNamespacedHelpers } from 'vuex';
// const { mapActions } = createNamespacedHelpers('b2b/purchase');

export default {
  name: 'filter-modal',
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonButton,
    IonList,
    IonItem,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonGrid,
    IonInput
  },
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      price: null,
      unit_price: null,
      labelPrice: null
    };
  },
  mounted() {
    this.unit_price = (Number(this.product.total_price) / this.product.amount_qty).toFixed(2);
    this.price = Number(this.product.total_price).toFixed(2);
    this.labelPrice = `Price Per ${this.product.sku.oom.name}`;
  },
  methods: {
    // ...mapActions([ACTIONS.PRICE_ADD]),
    updatePrice(val) {
      this.unit_price = Number(val);
      this.price = this.unit_price * this.product.amount_qty;
    },

    updateSellingPrice(val) {
      this.price = Number(val);
      this.unit_price = this.price / this.product.amount_qty;
    },

    formatPrice() {
      this.unit_price = this.unit_price.toFixed(2);
      this.price = this.price.toFixed(2);
    },

    saveChangePrice() {
      // this[ACTIONS.PRICE_ADD]({
      //   skuId: this.product.sku.id,
      //   buyerId: this.unit_price,
      //   price: parseFloat(this.price),
      //   lastUserId: this.product.customer_buyer_id
      // });
      this.$emit('close-modal');
    }
  }
};
</script>

<style scoped lang="scss">
.name {
  color: #64ae0f;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

ion-content::part(scroll) {
  overflow: hidden;
}

ion-label,
ion-select,
ion-datetime {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  line-height: 22px;
}
</style>
