<template>
  <ion-item v-show="showUpdatedItem">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-text>
            {{ item.product_name }}
          </ion-text>
        </ion-col>
        <ion-col size="12">
          <ion-text>{{ showPrice(item.sale_unit_price) }} ({{ item.qty }} {{ item.oom }}(s))</ion-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <b> {{ $t('previous') }}: </b>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="3"> {{ showPrice(oldItem.sale_unit_price) }} </ion-col>
        <ion-col v-if="!item.is_order_by_weight" size="2"> x {{ oldItem.total_qty }} </ion-col>
        <ion-col v-else size="2"> {{ oldItem.total_qty }} {{ $t('kg') }} </ion-col>
        <ion-col size="4" class="d-flex justify-end">=</ion-col>
        <ion-col size="3"> {{ showPrice(oldItem.total_price) }} </ion-col>
      </ion-row>
      <ion-row class="updated">
        <ion-col size="12">
          <b> {{ $t('updated') }}: </b>
        </ion-col>
      </ion-row>
      <ion-row class="deleted" v-if="item.isDelete">
        <ion-col size="3"> {{ showPrice(item.sale_unit_price) }} </ion-col>
        <ion-col v-if="!item.is_order_by_weight" size="2"> x {{ 0 }} </ion-col>
        <ion-col v-else size="2"> {{ 0 }} {{ $t('kg') }} </ion-col>
        <ion-col size="4" class="d-flex justify-end">=</ion-col>
        <ion-col size="3"> {{ $t('removed') }}</ion-col>
      </ion-row>
      <ion-row class="updated" v-else>
        <ion-col size="3"> {{ showPrice(item.sale_unit_price) }} </ion-col>
        <ion-col v-if="!item.is_order_by_weight" size="2"> x {{ item.qty }} </ion-col>
        <ion-col v-else size="2"> {{ item.qty }} {{ $t('kg') }} </ion-col>
        <ion-col size="4" class="d-flex justify-end">=</ion-col>
        <ion-col size="3"> {{ showPrice(item.origin_unit_price) }} </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</template>
<script>
import { showPriceWithCurrencySymbol } from '@/modules/shared/utils/';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  props: {
    item: {
      required: true
    },
    oldItem: {
      required: true
    },
    currencySymbol: {
      required: true
    }
  },

  setup(props) {
    const showPrice = showPriceWithCurrencySymbol(props.currencySymbol);

    const showUpdatedItem = computed(() => {
      let isItemChanges = false;
      if (props.item.isDelete) {
        isItemChanges = true;
      } else {
        isItemChanges = parseInt(props.oldItem.total_qty) === parseInt(props.item.qty) ? false : true;
      }
      return isItemChanges;
    });

    return {
      showPrice,
      showUpdatedItem
    };
  }
});
</script>
<style lang="scss" scoped>
ion-item {
  font-size: 14px;
  --padding-start: 0px;
}
.updated {
  color: green;
}
.deleted {
  color: red;
}
</style>
