<template>
  <div @click="show = !show">
    <div class="d-flex justify-space-between">
      <ion-text
        ><p><b>Change Summary</b></p>
      </ion-text>
      <ion-button fill="clear">
        <transition name="caret">
          <div>
            <ion-icon :icon="caretDownOutline"></ion-icon>
          </div>
        </transition>
      </ion-button>
    </div>
    <transition name="slide">
      <div v-show="show">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { caretDownOutline } from 'ionicons/icons';
export default defineComponent({
  setup() {
    const show = ref(false);
    return {
      show,
      caretDownOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.slide-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slide-enter-from,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.caret-enter-active,
.caret-leave-active {
  transition: all 0.5s;
}
.caret-enter-from,
.caret-leave-to {
  transform: rotate(180deg);
}
</style>
