<template>
  <ion-grid class="px-3">
    <ion-row style="margin-top: 10px">
      <ion-col size="12">
        <ion-skeleton-text animated style="width: 74%; height: 20px; margin-left: 13%"></ion-skeleton-text>
      </ion-col>
    </ion-row>

    <ion-row class="mt-10">
      <ion-col size="9">
        <ion-label class="title">
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </ion-label>
      </ion-col>
      <ion-col size="3" class="justify-end">
        <ion-label class="title">
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row class="align-center">
      <ion-col size="10">
        <ion-text class="medium fs-12px">
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </ion-text>
      </ion-col>

      <ion-col size="2" class="d-flex">
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="3">
        <ion-skeleton-text animated style="width: 100%; height: 25px"></ion-skeleton-text>
      </ion-col>
      <ion-col size="7" />
      <ion-col size="2">
        <ion-skeleton-text animated style="width: 100%; height: 25px"></ion-skeleton-text>
      </ion-col>
    </ion-row>
    <ion-skeleton-text class="mt-2 mx-1" animated style="width: 98%; height: 42px"></ion-skeleton-text>
  </ion-grid>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.title {
  height: 16px;
}

.thumbnail {
  width: 63px;
  height: 63px;
}

.icon-halal {
  width: 22px;
  height: 22px;
}
ion-skeleton-text {
  --border-radius: 8px;
}
</style>
