<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-2" @click="$emit('close-modal')">
          <ion-icon :icon="chevronBackOutline"></ion-icon>
        </ion-buttons>
        <ion-label class="fs-3 fw-600 mt-1">{{ $t('delivery_date_time') }}</ion-label>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="!isLoadingRef">
      <div>
        <div class="date mt-2 pa-2">
          <ion-label class="fs-3 text-uppercase" color="grey">{{ $t('select_delivery_date') }}</ion-label>
        </div>
        <ion-slides class="slides-cate" :options="slideOpts">
          <ion-slide
            v-for="(date, index) in deliveryDates"
            :key="index"
            :class="!date.isAvailable ? 'not-available' : ''"
          >
            <div
              :class="['d-flex flex-column align-center', !date.isAvailable ? 'disable-click' : '']"
              @click="selectDate(date.data)"
            >
              <div class="d-block date" :class="{ active: selectedDate === date.data }">
                <div class="pb-2 fs-3 text-grey">
                  {{ index === 0 ? $t('today') : formatDay(date.data) }}
                </div>
                <ion-text>
                  {{ formatDateMonth(date.data) }}
                </ion-text>
              </div>
              <ion-text class="fs-1">{{ !date.isAvailable ? $t('unavailable') : '' }}</ion-text>
            </div>
          </ion-slide>
        </ion-slides>
      </div>
      <ion-label class="fs-3 text-uppercase pt-4 pl-2" color="grey">{{
        $t('select_delivery_time')
      }}</ion-label>
      <ion-grid class="time pa-2">
        <ion-row>
          <ion-col>
            <ion-radio-group :value="selectedTime">
              <ion-item
                lines="none"
                class="ion-no-padding mb-1"
                v-model="selectedTime"
                v-for="(item, index) in deliveryTimes"
                :key="index"
                @click="selectTime(item.deliveryTimes)"
              >
                <ion-label color="dark">
                  <div>{{ item.deliveryTimes }}</div>
                </ion-label>
                <ion-radio
                  :value="item.deliveryTimes"
                  :disabled="!isTimeSlotAvailable(selectedDate, item)"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-footer v-if="!isLoadingRef">
      <ion-toolbar class="pa-2">
        <ion-button color="primary" expand="block" @click="confirm()" :disabled="!selectedTime">
          <span>{{ $t('confirm') }}</span>
        </ion-button>
      </ion-toolbar>
    </ion-footer>

    <ion-loading
      :is-open="isLoadingRef"
      cssClass="my-custom-class"
      message="Please wait..."
      @didDismiss="setOpen(false)"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent, inject, onMounted, ref } from 'vue';

import { openToast } from '@/modules/shared/utils/toast';
import { executeApolloClient } from '@/services/shared/apollo-client';
import { getAvailableDeliverySlots, getDeliveryTimes } from '@/services/shared/graphql';
import { useDateFormatter } from '@/usecases/global';
import { chevronBackOutline } from 'ionicons/icons';
// import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'delivery-date-time',
  emits: ['confirm', 'close-modal'],
  props: {
    deliveryDate: {
      type: String,
      default: ''
    },
    deliveryTime: {
      type: String,
      default: ''
    },
    currentCartItems: {
      type: Array,
      default: new Array([])
    }
  },
  setup(props, { emit }) {
    const { formatDay, formatDateMonth, formatDatePayload } = useDateFormatter();
    const storage = inject('$storage');
    // const { t } = useI18n();
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto'
    };

    const deliveryDates = ref([]);
    const deliveryTimes = ref([]);
    const deliveryTimeSlots = ref([]);
    const selectedDate = ref(props.deliveryDate);
    const selectedTime = ref(props.deliveryTime);
    const isLoadingRef = ref(true);
    const setOpen = (state) => (isLoadingRef.value = state);

    const selectDate = (date) => {
      selectedDate.value = date;
      if (date !== props.deliveryDate) {
        selectedTime.value = null;
      }
      const selectedDeliveryTime = deliveryTimes.value.find((dt) => dt.deliveryTimes === selectedTime.value);
      if (
        !isTimeSlotAvailable(selectedDate.value, selectedDeliveryTime) &&
        selectedDate.value === props.deliveryDate
      ) {
        selectedTime.value = deliveryTimes.value.find((dt) =>
          isTimeSlotAvailable(selectedDate.value, dt)
        )?.deliveryTimes;
      }
    };
    const selectTime = (time) => {
      selectedTime.value = time;
    };
    const confirm = () => {
      emit('confirm', {
        deliveryDate: selectedDate.value ? formatDatePayload(selectedDate.value) : '',
        deliveryTime: selectedTime.value
      });
    };
    const isTimeSlotAvailable = (date, deliveryTime) => {
      let isAvailable = false;
      if (!deliveryTime) return isAvailable;

      for (const ds of deliveryTimeSlots.value) {
        if (ds.date === date) {
          for (const slot of ds.slots) {
            if (slot.label === deliveryTime.deliveryTimes) {
              isAvailable = slot.isAvailable;
              break;
            }
          }
        }
      }

      return isAvailable;
    };

    const getAvailableTimeSlots = async (tenantId) => {
      const items = props.currentCartItems.map((item) => ({
        quantity: item.total_qty,
        sku_id: item.sku_id
      }));
      const payload = {
        userActionDatetime: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        items,
        tenantId,
        daysLimit: 30,
        isNewOrder: false
      };
      try {
        const res = await executeApolloClient(getAvailableDeliverySlots, payload);
        deliveryTimeSlots.value = res.getAvailableDeliverySlots;
        deliveryDates.value = deliveryTimeSlots.value.map((item) => ({
          data: item.date,
          isAvailable: item.isAvailable
        }));
      } catch (e) {
        openToast(e.message);
      }
    };

    const getDeliveryTime = async (tenantId) => {
      try {
        const res = await executeApolloClient(getDeliveryTimes, {
          active: true,
          tenant_id: tenantId
        });
        deliveryTimes.value = res.getDeliveryTimes;
      } catch (e) {
        openToast(e.message);
      }
    };

    onMounted(async () => {
      const user = await storage.getUser();
      await getAvailableTimeSlots(user.tenant.id);
      await getDeliveryTime(user.tenant.id);
      isLoadingRef.value = false;
    });

    return {
      deliveryTimes,
      selectedDate,
      selectedTime,
      chevronBackOutline,
      slideOpts,
      dayjs,
      isLoadingRef,
      setOpen,
      selectDate,
      selectTime,
      confirm,
      isTimeSlotAvailable,
      formatDay,
      formatDateMonth,
      deliveryDates
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/css/modal/deliveryTime.scss';
</style>
