import { apolloClient } from '@/main';

export const executeApolloClient = async (query, variables) => {
  const res = await apolloClient.query({
    query,
    variables
  });

  if (res.errors) {
    throw new Error(res.errors[0].message);
  } else {
    return res.data;
  }
};
