<template>
  <ion-page>
    <bottom-sheet :dismissModal="closeModal" :title="title">
      <template #content>
        <div class="my-2">
          <SlideDown v-if="!isCancelOrder" class="px-3 container">
            <div class="text-uppercase">
              <ion-text color="grey5"
                ><b class="fs-2">{{ $t('order_item') }}</b></ion-text
              >
            </div>
            <change-summary-item
              v-for="item in items"
              :item="item"
              :currencySymbol="currencySymbol"
              :oldItem="getItemById(item.orderItemId)"
              :key="item.orderItemId"
            />
          </SlideDown>
          <div class="px-3 container mt-1 py-3">
            <div>
              <p class="fs-2 mt-0 mb-1">
                <b>{{ $t('select_reason') }}</b>
              </p>
              <p class="fs-13 my-0">{{ $t('let_us_know') }}</p>
            </div>
            <ion-radio-group v-model="selectValue">
              <ion-item no-lines v-for="item in reasons" :key="item.id">
                <ion-label
                  ><p class="reason-selection fs-2" color="dark">{{ item.name }}</p></ion-label
                >
                <ion-radio slot="end" :value="item.id" />
              </ion-item>
            </ion-radio-group>
            <div class="pl-3 px-3">
              <div>
                <ion-text
                  ><p>
                    {{ $t('input_editional_info') }}{{ isNotSelectOther ? '' : ' ' + $t('required') }}
                  </p></ion-text
                >
                <div>
                  <ion-textarea
                    rows="1"
                    :placeholder="$t('input_your_reasons')"
                    maxlength="50"
                    v-model="textareaValue"
                    class="pl-2"
                  />
                </div>
                <div class="d-flex justify-space-between">
                  <ion-text color="text-danger"
                    ><p v-show="isNotValidInputAndSelectOther">{{ $t('minimum_5_char') }}</p></ion-text
                  >
                  <ion-text>
                    <p class="text-grey">{{ textareaValue.length }}/50</p>
                  </ion-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="d-flex flex-column px-3 py-3">
          <custom-button
            expand="block"
            mode="ios"
            :disabled="isNotValidInputAndSelectOther"
            @click="confirmOrder"
            >{{ isCancelOrder ? $t('confirm_cancel') : $t('updateOrder') }}</custom-button
          >
          <custom-button expand="block" mode="ios" fill="outline" @click="closeModal">{{
            $t('cancel')
          }}</custom-button>
        </div>
      </template>
    </bottom-sheet>
  </ion-page>
</template>
<script>
import CustomButton from '@/components/atoms/button/Button.vue';
import BottomSheet from '@/components/molecules/bottom-sheet/BottomSheet.vue';
import SlideDown from '@/components/molecules/slide-down/SlideDown.vue';
import { apolloClient } from '@/main';
import { saleGetReasonType } from '@/modules/sale/services/graphql';
import { computed, defineComponent, onMounted, ref } from 'vue';
import ChangeSummaryItem from './ChangeSummaryItem.vue';

export default defineComponent({
  components: {
    BottomSheet,
    ChangeSummaryItem,
    SlideDown,
    CustomButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Object,
      required: true
    },
    oldItems: {
      type: Object,
      required: true
    },
    currencySymbol: {
      type: String,
      required: true
    },
    isCancelOrder: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close-modal', 'confirm-update', 'cancel-order'],
  setup(props, { emit }) {
    const reasons = ref([]);
    const selectValue = ref(reasons.value[0]?.id);
    const textareaValue = ref('');

    const isNotSelectOther = computed(() => {
      return selectValue.value !== reasons.value[2]?.id;
    });
    const isNotValidInputAndSelectOther = computed(() => {
      return selectValue.value === reasons.value[2]?.id && isNotValidInput.value;
    });
    const isNotValidInput = computed(() => {
      return textareaValue.value.length < 5 || textareaValue.value.length > 50;
    });
    const getItemById = (id) => {
      return props.oldItems.find((item) => item.id === id);
    };
    const closeModal = () => {
      emit('close-modal');
    };
    const confirmOrder = () => {
      const nameEvent = props.isCancelOrder ? 'cancel-order' : 'confirm-update';
      emit(nameEvent, {
        reason: textareaValue.value,
        reasonTypeId: selectValue.value
      });
    };

    const getReasons = async () => {
      const { data } = await apolloClient.mutate({
        mutation: saleGetReasonType,
        variables: { active: true }
      });
      reasons.value = data.getReasonType;
      selectValue.value = data.getReasonType[0]?.id;
      return data;
    };
    onMounted(() => {
      getReasons();
    });

    return {
      reasons,
      getItemById,
      confirmOrder,
      closeModal,
      isNotSelectOther,
      isNotValidInput,
      selectValue,
      textareaValue,
      isNotValidInputAndSelectOther
    };
  }
});
</script>
<style lang="scss" scoped>
.container {
  background-color: var(--ion-color-background);
}
ion-textarea {
  --background: #eeeeee;
  --border-radius: 33px;
}
.text-grey {
  color: #c0c0c0;
}
.reason-selection {
  color: #000000;
}
</style>
