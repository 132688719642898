<template>
  <div class="block-button" @click.stop="viewDetail()">
    <ion-row>
      <ion-icon :icon="helpOutline" class="help-icon" />
      <ion-text>
        {{ t('why_my_order_is_on_hold') }}
        <ion-text class="text-bold">{{ t('tap_to_view_info') }}</ion-text>
      </ion-text>
    </ion-row>
    <ion-icon :icon="chevronForwardOutline" class="forward-icon" />
  </div>

  <ion-alert
    :isOpen="isAlertOpen"
    :header="t('order_is_pending_acceptance')"
    :buttons="alertButtons"
    backdropDismiss="false"
    cssClass="alert-pending-supplier-acceptance"
    @didDismiss="setAlertOpen(false)"
  ></ion-alert>

  <ion-modal
    :is-open="isOpenReasonPageRef"
    ref="modal"
    trigger="open-block-order-reason-modal"
    :initial-breakpoint="1"
    :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
    css-class="default-bottom-sheet-modal"
    @didDismiss="setOpenReasonPageRef(false)"
  >
    <ion-page>
      <ion-header>
        <ion-toolbar class="px-2">
          <ion-label slot="start" class="fs-3 fw-bold">{{ t('order_on_hold') }}</ion-label>
          <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="closeModal"></ion-icon>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-card>
          <ion-card-header class="ion-text-center card-header">
            <ion-icon :icon="informationCircleOutline" class="info-icon"></ion-icon>
            <ion-card-title class="card-title">{{ t('credit_limit_exceeded') }}</ion-card-title>
            <ion-card-subtitle class="card-subtitle">{{
              userType === 'b2b'
                ? t('please_make_payment_or_contact_supplier')
                : t('please_make_payment_or_contact_admin')
            }}</ion-card-subtitle>
          </ion-card-header>

          <span class="divider"></span>

          <ion-card-content>
            <ion-row class="reason-item" v-for="(reason, index) in blockOrderReasons" :key="index">
              <ion-col class="reason-item__title"> {{ getReasonTitle(reason.block_reason) }}: </ion-col>
              <ion-col
                class="flex-row-wrap"
                v-if="['outstanding_limit_hit', 'overdue_amount_hit'].includes(reason.block_reason)"
              >
                <span class="reason-item__value"
                  >{{ currencySymbol }} {{ formatAmountToCommaThousand(reason.value) }}</span
                >
                <span class="reason-item__limit_value"
                  >(Max. {{ currencySymbol }} {{ formatAmountToCommaThousand(reason.limit_value) }})</span
                >
              </ion-col>
              <ion-col class="flex-row-wrap" v-else>
                <span class="reason-item__value">{{ reason.value }}</span>
                <span class="reason-item__limit_value">(Max. {{ reason.limit_value }})</span>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-content>

      <ion-footer>
        <ion-toolbar class="mb-3 px-2">
          <ion-button class="ion-margin-top foot-btn" expand="block" @click="onClickMakePayment">{{
            t('make_payment')
          }}</ion-button>
          <ion-button
            class="ion-margin-top foot-btn-outline"
            expand="block"
            fill="outline"
            v-if="userType === 'b2b'"
            @click="onClickContactSupplier"
            >{{ t('contact_supplier') }}</ion-button
          >
          <ion-button
            class="ion-margin-top foot-btn-outline"
            expand="block"
            fill="clear"
            @click="closeModal"
            >{{ t('later') }}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-modal>
</template>

<script>
import { apolloClient } from '@/main';
import { getOrderBlockReasons } from '@/modules/shared/services/graphql';
import { getSupplierContactInfo } from '@/services/shared/graphql';
import Clevertap from '@/services/shared/helper/clevertap';
import { alertController, toastController } from '@ionic/vue';
import { chevronForwardOutline, closeOutline, helpOutline, informationCircleOutline } from 'ionicons/icons';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'BlockOrderDetail',
  component: {},
  emits: ['close-modal'],
  props: {
    orderId: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const storage = inject('$storage');
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const blockOrderReasons = ref([]);
    const isOpenReasonPageRef = ref(false);
    const setOpenReasonPageRef = (state) => (isOpenReasonPageRef.value = state);
    const currencySymbol = ref('');
    const isAlertOpen = ref(false);
    const setAlertOpen = (state) => (isAlertOpen.value = state);
    const tenantId = ref(0);
    const customerId = ref(0);
    const userType = ref('');

    const alertButtons = [
      {
        text: t('contact_supplier'),
        cssClass: 'alert-button-text-bold',
        handler: async () => {
          await onClickContactSupplier();
        }
      },
      {
        text: t('later'),
        cssClass: 'alert-button-text-normal',
        role: 'dismiss'
      }
    ];

    const closeModal = () => {
      setOpenReasonPageRef(false);
    };

    const getReasonTitle = (blockReason) => {
      switch (blockReason) {
        case 'outstanding_limit_hit':
          return 'Outstanding Amount';
        case 'outstanding_date_count_hit':
          return 'Outstanding Order(s)';
        case 'overdue_amount_hit':
          return 'Overdue Amount';
        case 'overdue_date_count_hit':
          return 'Overdue Invoice(s)';
        default:
          return '';
      }
    };

    const isLimitBlock = () => {
      return blockOrderReasons.value.some(({ block_reason }) =>
        [
          'outstanding_limit_hit',
          'outstanding_date_count_hit',
          'overdue_amount_hit',
          'overdue_date_count_hit'
        ].includes(block_reason)
      );
    };

    const viewDetail = async () => {
      try {
        const { data, errors } = await apolloClient.query({
          query: getOrderBlockReasons,
          variables: {
            orderId: Number(props.orderId)
          }
        });

        if (errors?.length) {
          throw new Error(errors[0].message);
        }

        blockOrderReasons.value = data.getOrderBlockReasons;

        if (!blockOrderReasons.value?.length) {
          const alert = await alertController.create({
            header: t('order_no_longer_blocked'),
            mode: 'ios',
            buttons: [
              {
                text: t('Ok'),
                role: 'dismiss'
              }
            ]
          });
          await alert.present();
          return;
        }

        if (isLimitBlock()) {
          setOpenReasonPageRef(true);
        } else {
          setAlertOpen(true);
        }
      } catch (e) {
        const toast = await toastController.create({
          message: e.message,
          duration: 3000,
          color: 'danger',
          position: 'top'
        });
        await toast.present();
      }
    };

    const formatAmountToCommaThousand = (amountString) => {
      return Number(amountString || 0).toLocaleString('en-US', { minimumFractionDigits: 2 });
    };

    const onClickMakePayment = () => {
      setOpenReasonPageRef(false);
      setAlertOpen(false);
      Clevertap.onUserMakePaymentToUnblockSoftBlockedOrder();
      if (userType.value) {
        router.push(`/${userType.value}/invoices/select-invoices/`);
      }
    };

    const onClickContactSupplier = async () => {
      setOpenReasonPageRef(false);
      setAlertOpen(false);

      try {
        const { data, errors } = await apolloClient.query({
          query: getSupplierContactInfo,
          variables: {
            customerId: customerId.value,
            tenantId: tenantId.value
          }
        });

        if (errors?.length) {
          throw new Error(errors[0].message);
        }

        const contactNumber = data.getSupplierContactInfo?.customerServiceNumber;
        const contactType = data.getSupplierContactInfo?.preferredCommunicationChannel;

        if (!contactNumber) {
          const toast = await toastController.create({
            message: t('contact_your_supplier'),
            duration: 3000,
            color: 'primary',
            position: 'top'
          });
          await toast.present();
          return;
        }

        if (contactType.toLowerCase() === 'whatsapp') {
          const whatsappMessage = t('i_have_question_about_order', {
            orderId: props.orderId
          });
          const whatsappURL = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${encodeURIComponent(
            whatsappMessage
          )}`;

          window.open(whatsappURL, '_blank');
        } else {
          window.location.href = `tel:${contactNumber}`;
        }
      } catch (e) {
        const toast = await toastController.create({
          message: e.message,
          duration: 3000,
          color: 'danger',
          position: 'top'
        });
        await toast.present();
      }
    };

    onMounted(async () => {
      const user = await storage.getUser();
      const selectedCompany = await storage.getSelectedCompany();
      tenantId.value = user?.tenant?.id || 0;
      customerId.value = selectedCompany?.id || 0;
      currencySymbol.value = user?.currency_symbol || '';

      // get user type by url
      const currentPathUrl = window.location.pathname;
      const pathSegments = currentPathUrl.split('/');
      userType.value = pathSegments[1];
    });

    return {
      route,
      router,
      t,
      helpOutline,
      chevronForwardOutline,
      closeOutline,
      informationCircleOutline,
      isOpenReasonPageRef,
      setOpenReasonPageRef,
      closeModal,
      blockOrderReasons,
      currencySymbol,
      getReasonTitle,
      isLimitBlock,
      viewDetail,
      formatAmountToCommaThousand,
      isAlertOpen,
      setAlertOpen,
      alertButtons,
      onClickMakePayment,
      onClickContactSupplier,
      customerId,
      tenantId,
      userType
    };
  }
});
</script>

<style lang="scss" scoped>
ion-content {
  --background: #f5f5f5;
}
ion-toolbar {
  --background: white;
}
.block-button {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 36px;
  align-items: center;
}
.help-icon {
  display: block;
  background: transparent;
  padding: 1px;
  border: 1px solid #757575;
  color: #757575;
  border-radius: 100%;
  font-size: 10px;
  margin: 0 6px 0 10px;
}
.forward-icon {
  display: block;
  background: transparent;
  padding: 1px;
  color: #757575;
  font-size: 15px;
  margin-right: 10px;
}
.info-icon {
  background: transparent;
  color: #eb8c31;
  width: 40px;
  height: 40px;
}
.text-bold {
  font-weight: 500;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 5px;
  border-radius: 20px;
}

.card-title {
  font-size: 15px;
  font-weight: 500;
  color: #212121;
}
.card-subtitle {
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
  color: #212121;
  margin-top: 8px;
}
.divider {
  display: block;
  border-bottom: 1px solid var(--grey-300, #e0e0e0);
  margin: 0 20px;
}
.reason-item {
  font-size: 13px;
  .reason-item__title {
    font-weight: 400;
    color: #424242;
  }
  .reason-item__value {
    font-size: 13px;
    font-weight: 700;
    color: #f04438;
  }
  .reason-item__limit_value {
    font-size: 13px;
    font-weight: 400;
    color: #9e9e9e;
    margin-left: 5px;
  }
}
.flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}
</style>
