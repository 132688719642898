<template>
  <ion-modal
    mode="ios"
    :is-open="isOpenModalDateTime"
    :backdropDismiss="false"
    css-class="modal-ion-datetime"
  >
    <ion-page>
      <ion-content class="no-scroll">
        <ion-datetime
          mode="ios"
          @ionChange="
            (e) => {
              time = e.detail.value;
            }
          "
          ref="customDatetime"
          :value="initialization_time"
          :locale="locale"
          presentation="date"
          display-format="DD MMM YYYY"
        >
        </ion-datetime>
      </ion-content>
      <ion-footer class="footer">
        <ion-text color="danger" @click="handleSubmitDateTime((isDone = false))">{{ $t('cancel') }}</ion-text>
        <ion-text color="primary" @click="handleSubmitDateTime((isDone = true))">{{ $t('done') }}</ion-text>
      </ion-footer>
    </ion-page>
  </ion-modal>
</template>
<script>
import { useDateFormatter } from '@/usecases/global/';
import { onMounted, ref } from 'vue';

export default {
  props: {
    initialization_time: {
      type: String,
      default: ``
    },
    presentation: {
      type: String,
      default: 'date'
    }
  },
  emits: ['handleSendTimeChange'],
  setup(props, ctx) {
    const { formatISO8601, locale } = useDateFormatter();
    const isOpenModalDateTime = ref(true);
    const customDatetime = ref();
    const time = ref(null);
    onMounted(() => {
      time.value = props.initialization_time || formatISO8601(new Date().toLocaleDateString());
    });
    const handleSubmitDateTime = (params) => {
      if (!time.value) return;
      if (customDatetime.value === undefined) return;
      params
        ? ctx.emit('handleSendTimeChange', time.value)
        : ctx.emit('handleSendTimeChange', props.initialization_time);
      isOpenModalDateTime.value = false;
    };
    return {
      locale,
      isOpenModalDateTime,
      customDatetime,
      time,
      handleSubmitDateTime
    };
  }
};
</script>
<style lang="scss" scoped>
.no-scroll {
  --overflow: hidden;
}
ion-button {
  width: 80px;
  text-transform: none;
  --border-radius: 8px;
}
.footer {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  & > ion-text {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
