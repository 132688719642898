<template>
  <ion-header>
    <div class="icon-scroll" />
    <ion-toolbar class="pb-1 pl-0 p-r-1 my-0 ion-no-border" mode="ios">
      <ion-title class="pl-3 ion-text-start fs-31">{{ title }}</ion-title>
      <ion-icon slot="end" :icon="closeOutline" @click="dismissModal" />
    </ion-toolbar>
  </ion-header>
  <ion-content color="background-modal">
    <slot name="content" />
  </ion-content>
  <ion-footer>
    <slot name="footer" />
  </ion-footer>
</template>
<script>
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    dismissModal: {
      required: true
    }
  },
  setup() {
    return {
      closeOutline
    };
  }
});
</script>
<style lang="scss" scoped>
ion-icon {
  color: black;
  font-size: 20px;
  background-color: #989aa226;
  padding: 5px;
  border-radius: 20px;
}
ion-title {
  font-size: 20px;
  font-weight: 500;
}
ion-toolbar {
  padding: 8px 16px;
}
</style>
