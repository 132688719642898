// eslint-disable-next-line vue/valid-template-root
<template>
  <ion-page>
    <ion-header translucent class="ion-no-border pa-2">
      <ion-toolbar>
        <ion-text slot="start" class="fs-3 fw-500">{{ $t('companies') }}</ion-text>
        <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="closeModal"></ion-icon>
      </ion-toolbar>
    </ion-header>

    <div>
      <ion-grid>
        <ion-row class="ion-align-items-start px-2">
          <ion-col
            size="12"
            class="search-company"
            :class="isStartSearchRef ? 'new-search' : 'new-search-off'"
          >
            <ion-searchbar
              mode="ios"
              ref="searchInput"
              @ionChange="updateSearchKey"
              @ionFocus="setStartSearch(true)"
              @ionBlur="setStartSearch(false)"
              @keyup.enter="handleEnter"
              :debounce="1200"
              show-clear-button="never"
              :disabled="isShowSkeletonRef"
              class="search"
              :placeholder="$t('search_company')"
            >
            </ion-searchbar>
            <ion-icon
              v-show="searchOptions.searchQueries.length > 0"
              class="clear-search"
              :icon="closeCircle"
              @click="clearSearch"
            ></ion-icon>
          </ion-col>
          <ion-col size="12" class="ion-no-padding">
            <ion-item class="ion-no-padding mt-3 pb-2" lines="full">
              <ion-checkbox
                @click="toggleSelectAllCompanies($event)"
                color="primary"
                slot="start"
                ref="checkAllRef"
              ></ion-checkbox>
              <ion-text class="fs-3 fw-500">{{ $t('select_all') }}</ion-text>
              <div
                slot="end"
                class="selected-count"
                v-html="$t('selected_count', { selected_count: customerBuyerIdsRef.length })"
              ></div>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <ion-content ref="companiesList" :scroll-y="true" :scroll-events="true" id="content-companies">
      <div v-if="isShowSkeletonRef">
        <ion-item class="ion-no-padding" lines="none" v-for="(item, index) in 3" :key="index">
          <ion-label>
            <ion-skeleton-text :animated="true" style="width: 80%; height: 22px"> </ion-skeleton-text>
            <ion-skeleton-text class="mt-2" :animated="true" style="width: 40%; height: 22px">
            </ion-skeleton-text>
          </ion-label>
        </ion-item>
      </div>
      <div v-else-if="companyList.length > 0">
        <ion-item
          class="ion-no-padding item-company adjust-right-2"
          lines="full"
          v-for="customer in companyList"
          :key="customer.id"
          @click="toggleCustomerSelection($event, customer)"
        >
          <ion-label>
            <ion-text class="fs-3 fw-500">{{ customer.name }}</ion-text>
            <ion-text class="fs-3 fw-500 text-primary mt-1">{{ customer.account_number }}</ion-text>
          </ion-label>
          <ion-checkbox
            color="primary"
            slot="start"
            :checked="customerBuyerIdsRef.includes(customer.id) ? true : false"
          ></ion-checkbox>
        </ion-item>
      </div>
      <div v-else class="company-not-found mx-4">
        <ion-img src="/assets/images/not-found.svg" class="not-found-img" />
        <div class="not-found-text mt-3 mb-2">{{ $t('search_company_not_found') }}</div>
        <div
          class="try-different"
          v-html="
            $t('try_different_name', {
              company_search_name:
                searchOptions.searchQueries === '' ? refKeySearch : searchOptions.searchQueries
            })
          "
        ></div>
      </div>
      <ion-infinite-scroll
        threshold="100px"
        @ionInfinite="loadMore($event)"
        id="infinite-scroll"
        :disabled="isDisableLoadMore"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" :loading-text="$t('loading_infinity_scroll')">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
    <ion-footer class="ion-no-border">
      <ion-toolbar v-if="isShowSkeletonRef" style="--background: white">
        <ion-label>
          <ion-skeleton-text :animated="true" style="width: 100%; height: 42px"> </ion-skeleton-text>
          <ion-skeleton-text class="my-2" :animated="true" style="width: 100%; height: 42px">
          </ion-skeleton-text>
        </ion-label>
      </ion-toolbar>
      <ion-toolbar v-else style="--background: white">
        <ion-button class="foot-btn" expand="block" @click="handleSearchCompanies">{{
          $t('apply')
        }}</ion-button>
        <ion-button class="my-2 foot-btn-outline" expand="block" fill="outline" @click="closeModal">{{
          $t('cancel')
        }}</ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { getCustomersOrders } from '@/modules/b2b/services/graphql';
import { saleGetCustomersOrders } from '@/modules/sale/services/graphql';
import { executeApolloClient } from '@/services/shared/apollo-client';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { caretDownOutline, closeCircle, closeOutline } from 'ionicons/icons';
import { inject, reactive, ref } from 'vue';

export default {
  emits: ['close-modal', 'apply-selections'],
  props: {
    customerBuyerIds: {
      type: Object,
      default: new Array([])
    },
    customerBuyerNames: {
      type: Object,
      default: new Array([])
    }
  },
  setup(props, { emit }) {
    const isStartSearchRef = ref(false);
    const setStartSearch = (state) => (isStartSearchRef.value = state);
    const storage = inject('$storage');
    const searchInput = ref(null);

    const isShowSkeletonRef = ref(true);
    const setShowSkeleton = (state) => (isShowSkeletonRef.value = state);

    const customerBuyerIdsRef = ref([...props.customerBuyerIds]);
    const customerBuyerNamesRef = ref([...props.customerBuyerNames]);

    const initSearchOptions = {
      searchQueries: '',
      isIncludeTotalCount: true,
      limit: 20,
      offset: 0
    };

    const searchOptions = reactive({ ...initSearchOptions });

    const companyList = ref([]);
    const isDisableLoadMore = ref(false);
    const isSelectAll = ref(false);
    const checkAllRef = ref(null);
    let totalCount = 0;

    const closeModal = () => {
      emit('close-modal');
    };

    // fetch company list
    const performSearchCompany = async (isLoadMore = false) => {
      try {
        if (!isLoadMore) {
          isShowSkeletonRef.value = true;
        } else {
          Object.assign(searchOptions, { isIncludeTotalCount: false }); // don't need to include count for load more item
        }

        // check if user is admin/sales or merchant user
        const currentUser = await storage.getUser();
        const isAdminOrSales = currentUser.user_type_id === 2 || currentUser.user_type_id === 7;
        if (isAdminOrSales) {
          Object.assign(searchOptions, { tenantId: currentUser.tenant?.id });
        }
        const selectedQuery = isAdminOrSales ? saleGetCustomersOrders : getCustomersOrders;
        const selectedResultKey = isAdminOrSales ? 'saleGetCustomersOrders' : 'getCustomersOrders';
        const res = await executeApolloClient(selectedQuery, searchOptions);
        if (!isLoadMore) {
          companyList.value = res[selectedResultKey].customers;
          totalCount = res[selectedResultKey].totalCount;
        } else {
          companyList.value = [...companyList.value, ...res[selectedResultKey].customers];
        }
        // check to display load more item
        if (totalCount === companyList.value.length) {
          isDisableLoadMore.value = true;
        } else {
          isDisableLoadMore.value = false;
        }

        // update check all check box
        checkAllRef.value.$el.checked = companyList.value.every((obj) =>
          customerBuyerIdsRef.value.includes(obj.id)
        );

        isShowSkeletonRef.value = false;
      } catch (e) {
        isShowSkeletonRef.value = false;
        console.log(`performSearchCompany ERROR: ${e.message}`);
      }
    };

    const updateSearchKey = async () => {
      const currentSearchKey = searchInput.value.$el?.value;
      Object.assign(searchOptions, {
        searchQueries: currentSearchKey,
        isIncludeTotalCount: true,
        limit: 20,
        offset: 0
      });
      await performSearchCompany();
    };

    const clearSearch = async () => {
      searchInput.value.$el.value = '';
      Object.assign(searchOptions, {
        searchQueries: '',
        isIncludeTotalCount: true,
        limit: 20,
        offset: 0
      });
      await performSearchCompany();
    };

    const handleEnter = () => {
      if (Capacitor.isNativePlatform()) Keyboard.hide();
    };

    const loadMore = async (e) => {
      if (!isShowSkeletonRef.value && companyList.value.length) {
        searchOptions.offset = companyList.value.length;
        await performSearchCompany(true);
      }
      e.target.complete();
    };

    const toggleCustomerSelection = (e, company) => {
      if (!e.target.checked) {
        customerBuyerIdsRef.value = customerBuyerIdsRef.value.filter((id) => id !== company.id);
        customerBuyerNamesRef.value = customerBuyerNamesRef.value.filter((name) => name !== company.name);
      } else {
        customerBuyerIdsRef.value.push(company.id);
        customerBuyerNamesRef.value.push(company.name);
      }
      // update check all check box
      checkAllRef.value.$el.checked = companyList.value.every((obj) =>
        customerBuyerIdsRef.value.includes(obj.id)
      );
    };
    const toggleSelectAllCompanies = (e) => {
      if (e.target.checked) {
        // uncheck all selected showing in screen
        companyList.value.map((company) => {
          if (customerBuyerIdsRef.value.includes(company.id)) {
            customerBuyerIdsRef.value = customerBuyerIdsRef.value.filter((id) => id !== company.id);
            customerBuyerNamesRef.value = customerBuyerNamesRef.value.filter((name) => name !== company.name);
          }
        });
      } else {
        // uncheck all selected showing in screen
        companyList.value.map((company) => {
          if (!customerBuyerIdsRef.value.includes(company.id)) {
            customerBuyerIdsRef.value.push(company.id);
            customerBuyerNamesRef.value.push(company.name);
          }
        });
      }
    };

    const handleSearchCompanies = () => {
      emit('apply-selections', {
        customerBuyerIds: customerBuyerIdsRef.value,
        customerBuyerNames: customerBuyerNamesRef.value
      });
    };

    const handleScroll = (event) => {
      if (event && Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    };

    // trigger search company
    performSearchCompany();

    return {
      caretDownOutline,
      closeOutline,
      closeCircle,
      closeModal,
      isStartSearchRef,
      setStartSearch,
      isDisableLoadMore,
      loadMore,
      searchInput,
      isShowSkeletonRef,
      setShowSkeleton,
      searchOptions,
      companyList,
      customerBuyerIdsRef,
      customerBuyerNamesRef,
      toggleCustomerSelection,
      toggleSelectAllCompanies,
      isSelectAll,
      handleEnter,
      updateSearchKey,
      handleSearchCompanies,
      handleScroll,
      clearSearch,
      checkAllRef
    };
  }
};
</script>
<style lang="scss" scoped>
.name {
  color: #64ae0f;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

#hidden-scroll::part(scroll) {
  overflow: hidden;
}

ion-label,
ion-select,
ion-datetime {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px !important;
  line-height: 18px !important;
  line-height: 22px;
}
ion-text {
  padding-top: 4px;
  display: block;
}
.searchbar-input {
  height: 100px;
}
.btn-footer {
  display: flex;
}
#content-companies {
  --padding-start: 14px;
  --padding-end: 14px;
}
.item-danger {
  color: red !important;
}
#disable-click {
  pointer-events: none;
}
.foot-btn-outline {
  --border-radius: 10px;
  --color: #00676a;
}
.foot-btn {
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 3px;
  border-radius: 20px;
}
.selected-count {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #eb8c31;
}
.adjust-right {
  margin-right: -10px;
}
.adjust-right-2 {
  margin-right: -6px;
}
ion-searchbar {
  --background: transparent;
  --icon-color: #00676a;
  border: 3px solid transparent;
  margin-top: -12px;
  font-size: 15px;
}
.new-search {
  border: 1.3px solid #00676a;
  border-radius: 12px;
  height: 45px;
  background: #ffffff;
  color: black;
  width: 80vw;
}
.new-search-off {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  height: 45px;
  background: #f5f5f5;
  color: #9e9e9e;
  width: 80vw;
}
.search {
  padding-right: 0px;
  padding-left: 0px;
}
.company-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin-top: 6vh;
}
.not-found-img {
  height: 120px;
  width: 120px;
}
.not-found-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.7px;
}
.try-different {
  text-align: center;
}
.item-company {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > ion-checkbox {
    margin-top: 12px;
  }
}
.load-search {
  --background: transparent;
}
ion-skeleton-text {
  --border-radius: 8px;
}
.search-company {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  justify-content: space-between;
}
.clear-search {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 28px;
  color: #9e9e9e;
}
ion-spinner {
  position: absolute;
  left: 45%;
  z-index: 999999;
}
</style>
